import Popover from '@mui/material/Popover';
import React from 'react';
import useWindowDimensions from '../../utilities/hooks';
import ButtonIcon from '../../components/ButtonIcon';

interface Props {
    element: HTMLDivElement | null;
    onClose: () => void;
    onAdd: (status: boolean) => void;
    size?: number;
}

const LikePopover: React.FC<Props> = ({ element, size, onClose, onAdd }) => {
    const { width } = useWindowDimensions();

    return (
        <Popover
            open={element !== null}
            anchorEl={element}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPopover-paper': {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 5 }}>
                <ButtonIcon
                    icon="like"
                    onClick={() => {
                        onAdd(true);
                    }}
                    size={size ?? width * 0.015}
                />
                <ButtonIcon
                    icon="dislike"
                    onClick={() => {
                        onAdd(false);
                    }}
                    size={size ?? width * 0.015}
                />
            </div>
        </Popover>
    );
};

export default LikePopover;
