import { FC, useMemo } from 'react';
import SuccessBox from '../../../components/SuccessBox';
import Paragraph from '../../../components/Paragraph';
import Button from '../../../components/Button/Button';
import ErrorBox from '../../../components/ErrorBox';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Header from '../../../components/Header/Header';
import { Password } from '../ResetPasswordPage';
import { testPassword } from '../../../utilities/functions';

import './ResetPasswordPageDesktop.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    newPassword: Password;
    token: string;
    loading: boolean;
    errors: string[];
    success: string;
    onUpdatePassword: (newPassword: Password) => void;
    onBackToLogin: () => void;
    onResetPassword: () => void;
}

const ResetPasswordPageDesktop: FC<Props> = ({
    newPassword,
    token,
    loading,
    errors,
    success,
    onUpdatePassword,
    onBackToLogin,
    onResetPassword,
}) => {
    const { t } = useTranslation();
    const disableButton = useMemo(() => {
        const isPasswordValid = testPassword(newPassword.password);
        const isConfirmPasswordValid = newPassword.confirmPassword === newPassword.password;
        if (!token) {
            return true;
        }
        if (!isPasswordValid) {
            return true;
        }
        if (!isConfirmPasswordValid) {
            return true;
        }
        return false;
    }, [newPassword.confirmPassword, newPassword.password, token]);

    const errorMessage: string = useMemo(() => {
        if (errors.includes('email') || errors.includes('password') || errors.includes('confirmPassword')) {
            let message = t('errors.check_following_field_registration');
            if (errors.includes('password')) {
                const indexFound = errors.findIndex((text) => text === t('login_page.password'));
                message +=
                    indexFound + 1 === errors.length ? t('login_page.password') : `${t('login_page.password')}, `;
            }
            if (errors.includes('confirmPassword')) {
                const indexFound = errors.findIndex((text) => text === t('login_page.password_confirm'));
                message +=
                    indexFound + 1 === errors.length
                        ? t('login_page.password_confirm')
                        : `${t('login_page.password_confirm')}, `;
            }
            return message;
        }
        if (errors.length > 0) {
            if (errors[0] === 'reset token not found') {
                return t('errors.cant_reset_password');
            } else {
                return t(`errors.${errors[0]}`) ?? t('errors.cant_reset_password');
            }
        }
        return '';
    }, [errors, t]);

    return (
        <div className="resetPasswordContainer">
            <Header isInDetail={false} isResetPasswordPage />
            <div className="resetPasswordContainer-content">
                <Paragraph>
                    <p>{t('reset_password_page.insert_password')}</p>
                </Paragraph>
                <input
                    type="password"
                    value={newPassword.password}
                    onChange={(e) => onUpdatePassword({ ...newPassword, password: e.target.value })}
                    placeholder={t('reset_password_page.password')}
                />
                <input
                    type="password"
                    value={newPassword.confirmPassword}
                    onChange={(e) =>
                        onUpdatePassword({
                            ...newPassword,
                            confirmPassword: e.target.value,
                        })
                    }
                    placeholder={t('reset_password_page.confirm_password')}
                />
                {success ? (
                    <>
                        <SuccessBox message={success} />
                        <Paragraph>
                            <p onClick={onBackToLogin} style={{ color: '#fff', cursor: 'pointer' }}>
                                ← {t('reset_password_page.back_to_login')}
                            </p>
                        </Paragraph>
                    </>
                ) : (
                    <>
                        {errors.length > 0 ? <ErrorBox message={errorMessage} /> : null}
                        <Button
                            text={t('reset_password_page.reset_password')}
                            onClick={onResetPassword}
                            disabled={disableButton}
                        />
                    </>
                )}

                <LoadingModal isVisible={loading} />
            </div>
        </div>
    );
};

export default ResetPasswordPageDesktop;
