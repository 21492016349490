import { FC, useCallback, useMemo, useState } from 'react';
import Header from '../../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import TypeSelectorButton from '../../../components/TypeSelectorButton';
import { COLORS } from '../../../resources/constants';
import useWindowDimensions from '../../../utilities/hooks';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../resources/routes-constants';
import { User } from '../../../models/user';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Toast from '../../../components/Toast';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import { ContentType } from '../../../utilities/enum/contentType';

import './HomePageMobile.scss';

interface Props {
    user: User | null;
    askPreference: boolean;
    success: string;
    error: string;
    loading: boolean;
    onUpdatePreference: (status: boolean) => void;
    onStandard: (selectedType: ContentType) => void;
    onCloseToast: () => void;
}

const HomePageMobile: FC<Props> = ({
    user,
    askPreference,
    success,
    error,
    loading,
    onUpdatePreference,
    onStandard,
    onCloseToast,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState<ContentType | null>(null);

    const onMovieClick = useCallback(() => {
        if (user && askPreference) {
            setSelectedType(ContentType.MOVIE);
        } else {
            onStandard(ContentType.MOVIE);
        }
    }, [askPreference, onStandard, user]);

    const onTvShowClick = useCallback(() => {
        if (user && askPreference) {
            setSelectedType(ContentType.TV_SHOW);
        } else {
            onStandard(ContentType.TV_SHOW);
        }
    }, [askPreference, onStandard, user]);

    const renderModal = useMemo(() => {
        if (selectedType) {
            if (user && askPreference) {
                return (
                    <ConfirmModal
                        isVisible={selectedType && askPreference}
                        answer={
                            selectedType === ContentType.MOVIE
                                ? t('home_page.desktop.want_to_search_movie_modal')
                                : t('home_page.desktop.want_to_search_show_modal')
                        }
                        cancelText={t('home_page.desktop.no_totally_random')}
                        confirmText={t('home_page.desktop.yes_use_my_preferences')}
                        onCancel={() => onUpdatePreference(false)}
                        onConfirm={() => onUpdatePreference(true)}
                    />
                );
            }
        }
        return null;
    }, [selectedType, user, askPreference, t, onUpdatePreference]);

    return (
        <div className="homeContainer">
            <Header isInDetail={false} />
            <h2 className="homeContainer-suggestPhrase">{t('home_page.mobile.title_suggest')}</h2>
            <div className="homeContainer-selectionType">
                <TypeSelectorButton
                    color={COLORS.purple}
                    text={t('home_page.mobile.movie')}
                    size={width * 0.3}
                    onClick={onMovieClick}
                />
                <TypeSelectorButton
                    color={COLORS.yellow}
                    text={t('home_page.mobile.tv_show')}
                    size={width * 0.3}
                    onClick={onTvShowClick}
                />
            </div>
            <div className="homeContainer-titleButtonSection">
                <h3>{t('home_page.mobile.to_guide_suggestion_title')}</h3>
                <Button
                    onClick={() => {
                        navigate(ROUTES.FILTER_PAGE);
                    }}
                    text={t('home_page.mobile.to_guide_suggestion_button')}
                />
            </div>
            <div className="homeContainer-titleButtonSection">
                <h3>{t('home_page.mobile.or')}</h3>
                <Button
                    onClick={() => {
                        navigate(ROUTES.FEELINGS_PAGE);
                    }}
                    text={t('home_page.mobile.feelings_button')}
                />
            </div>
            {renderModal}
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={onCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default HomePageMobile;
