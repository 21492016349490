import { Feeling } from '../../models/data';
import {
    setLatestIdMovie,
    setLatestIdShow,
    setMovieGenres,
    setShowGenres,
    setMovieProviders,
    setShowProviders,
    setFeelings,
} from './data';
// import "firebase/compat/analytics";
import { getFeelings } from '../../api/db-requests';
import { getLatestMovie, getMoviesGenres, getMoviesProviders } from '../../api/movies-requests';
import { getLatestTvShow, getTvShowsGenres, getTvShowsProviders } from '../../api/tv-shows-requests';

export const dispatchLatestIdMovie: any = () => async (dispatch: (any: any) => void) => {
    const latestId = await getLatestMovie();
    dispatch(setLatestIdMovie(latestId));
};

export const dispatchLatestIdShow: any = () => async (dispatch: (any: any) => void) => {
    const latestId = await getLatestTvShow();
    dispatch(setLatestIdShow(latestId));
};

export const dispatchMovieGenres: any = () => async (dispatch: (any: any) => void) => {
    const genres = await getMoviesGenres();
    dispatch(setMovieGenres(genres));
};

export const dispatchShowGenres: any = () => async (dispatch: (any: any) => void) => {
    const genres = await getTvShowsGenres();
    dispatch(setShowGenres(genres));
};

export const dispatchMovieProviders: any = () => async (dispatch: (any: any) => void) => {
    const providers = await getMoviesProviders();
    dispatch(setMovieProviders(providers));
};

export const dispatchShowProviders: any = () => async (dispatch: (any: any) => void) => {
    const providers = await getTvShowsProviders();
    dispatch(setShowProviders(providers));
};

export const dispatchFeelings: any = () => async (dispatch: (any: any) => void) => {
    let feelings: Feeling[] = [];
    const fetched = await getFeelings();
    if (fetched && Array.isArray(fetched)) {
        feelings = fetched;
    } else {
        feelings = [];
    }
    dispatch(setFeelings(feelings));
};
