import React from 'react';
import './Icon.scss';

interface Props {
    name: string;
    size: number;
    color: string;
    style?: any;
    onClick?: () => void;
}

const Icon: React.FC<Props> = ({
    name,
    size,
    color,
    style = undefined,
    onClick = () => {
        return;
    },
}) => {
    return (
        <i
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
            className={`whatwatch-${name}`}
            style={{ display: 'flex', alignItems: 'center', fontSize: size, color: color, ...style }}
        />
    );
};

export default Icon;
