import { FC, useMemo, useState } from 'react';
import Header from '../../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../resources/routes-constants';
import { UserTab, userTabsArray } from '../../../utilities/enum/usertab';
import Paragraph from '../../../components/Paragraph';
import SearchPreferencesTab from './Tabs/SearchPreferencesTab';
import SettingsTab from './Tabs/SettingsTab';
import ContentListTab from './Tabs/ContentListTab';
import { useTranslation } from 'react-i18next';
import { User } from '../../../models/user';

import './AccountPageDesktop.scss';

interface Props {
    user: User | null;
    onLogout: () => void;
}

const AccountPageDesktop: FC<Props> = ({ user, onLogout }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState<UserTab>(UserTab.SEARCH_PREFERENCES);
    const navigate = useNavigate();

    const tabsRendered = useMemo(() => {
        const array = userTabsArray.map((tab, index) => {
            return (
                <Paragraph key={tab}>
                    <p
                        onClick={() => setSelectedTab(index)}
                        style={{ color: '#fff', cursor: 'pointer', opacity: index === selectedTab ? 1 : 0.6 }}
                    >
                        {t(`account_page.desktop.menu.${tab}`)}
                    </p>
                </Paragraph>
            );
        });
        array.push(
            <Paragraph key={'logout'}>
                <p onClick={onLogout} style={{ color: '#F316B4', cursor: 'pointer' }}>
                    {t('account_page.desktop.menu.signout')}
                </p>
            </Paragraph>,
        );
        return array;
    }, [onLogout, selectedTab, t]);

    const selectedTabRender = useMemo(() => {
        switch (selectedTab) {
            case UserTab.SEARCH_PREFERENCES:
                return <SearchPreferencesTab />;
            case UserTab.WATCHLIST:
                return <ContentListTab contentType="saved" />;
            case UserTab.WATCHED:
                return <ContentListTab contentType="watched" />;
            case UserTab.SETTINGS:
                return <SettingsTab />;
        }
    }, [selectedTab]);

    return (
        <div className={`accountContainer`}>
            <Header
                isAccountPage
                isInDetail={false}
                closeAccount={() => {
                    navigate(ROUTES.HOME_PAGE);
                }}
                tabs={tabsRendered}
            />
            <div className="accountContainer-tabRendered">{selectedTabRender}</div>
        </div>
    );
};

export default AccountPageDesktop;
