import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import useWindowDimensions from '../../utilities/hooks';
import ButtonIcon from '../../components/ButtonIcon';
import './FooterSettings.scss';

const FooterSettings: React.FC = () => {
    const { width } = useWindowDimensions();
    const navigate = useNavigate();

    return (
        <div className="footerSettings">
            <ButtonIcon
                icon="settings"
                size={width * 0.03}
                onClick={() => {
                    navigate(ROUTES.FILTER_PAGE);
                }}
            />
        </div>
    );
};

export default FooterSettings;
