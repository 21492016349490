import { USER_ACTIONS } from '../../store/actions/user';
import { ReducerUser, ReduxActionData } from '../../models/reducers';

const defaultValue: ReducerUser = {
    user: null,
    savedMovies: [],
    savedShows: [],
    watchedMovies: [],
    watchedShows: [],
    askPreference: true,
    isInstallablePwa: true,
};

const userState = (state: ReducerUser = defaultValue, action: ReduxActionData<any>): ReducerUser => {
    switch (action.type) {
        case USER_ACTIONS.SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        case USER_ACTIONS.SET_SAVED_MOVIES:
            return {
                ...state,
                savedMovies: action.payload,
            };
        case USER_ACTIONS.SET_SAVED_SHOWS:
            return {
                ...state,
                savedShows: action.payload,
            };
        case USER_ACTIONS.SET_WATCHED_MOVIES:
            return {
                ...state,
                watchedMovies: action.payload,
            };
        case USER_ACTIONS.SET_WATCHED_SHOWS:
            return {
                ...state,
                watchedShows: action.payload,
            };
        case USER_ACTIONS.SET_ASK_PREFERENCE:
            return {
                ...state,
                askPreference: action.payload,
            };
        case USER_ACTIONS.SET_IS_INSTALLABLE_PWA:
            return {
                ...state,
                isInstallablePwa: action.payload,
            };
        case USER_ACTIONS.LOGOUT:
            return { ...defaultValue, isInstallablePwa: state.isInstallablePwa };
        default:
            return state;
    }
};

export default userState;
