import { FC } from 'react';
import './ChangeType.scss';
import { COLORS } from '../../resources/constants';
import ButtonIcon from '../../components/ButtonIcon';
import useWindowDimensions from '../../utilities/hooks';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../utilities/enum/contentType';

interface Props {
    selectedType: ContentType;
    onChange: (type: ContentType) => void;
    showClose?: boolean;
    onCloseButton?: () => void;
}

const ChangeType: FC<Props> = ({
    selectedType,
    onChange,
    showClose = false,
    onCloseButton = () => {
        return;
    },
}) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    return (
        <div className="changeType">
            <span
                style={{
                    color: COLORS.purple,
                    opacity: selectedType === ContentType.MOVIE ? 1 : 0.6,
                }}
                onClick={() => {
                    onChange(ContentType.MOVIE);
                }}
            >
                {t('filter_page.desktop.movie')}
            </span>
            {showClose ? <ButtonIcon icon="close" onClick={onCloseButton} size={width * 0.03} /> : null}
            <span
                style={{
                    color: COLORS.yellow,
                    opacity: selectedType === ContentType.TV_SHOW ? 1 : 0.6,
                }}
                onClick={() => {
                    onChange(ContentType.TV_SHOW);
                }}
            >
                {t('filter_page.desktop.tv_show')}
            </span>
        </div>
    );
};

export default ChangeType;
