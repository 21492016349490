import { FC, useMemo, useState } from 'react';
import Header from '../../../components/Header/Header';
import TypeSelectorButton from '../../../components/TypeSelectorButton';
import { COLORS } from '../../../resources/constants';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../utilities/hooks';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Toast from '../../../components/Toast';
import FooterSettings from '../../../components/FooterSettings/FooterSettings';
import { User } from '../../../models/user';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import SelectSearchTypeModal from '../../../components/Modals/SelectSearchTypeModal';
import { Genre } from '../../../models/data';

import './HomePageDesktop.scss';
import { ContentType } from '../../../utilities/enum/contentType';

interface Props {
    user: User | null;
    askPreference: boolean;
    success: string;
    error: string;
    loading: boolean;
    onUpdatePreference: (status: boolean) => void;
    onFeelings: (genres: Genre[], feelingId: number, selectedType: ContentType) => void;
    onStandard: (selectedType: ContentType) => void;
    onCloseToast: () => void;
}

const HomePageDesktop: FC<Props> = ({
    user,
    askPreference,
    success,
    error,
    loading,
    onUpdatePreference,
    onFeelings,
    onCloseToast,
    onStandard,
}) => {
    const { t } = useTranslation();
    const { height } = useWindowDimensions();
    const [selectedType, setSelectedType] = useState<ContentType | null>(null);

    const renderModal = useMemo(() => {
        if (selectedType) {
            if (user && askPreference) {
                return (
                    <ConfirmModal
                        isVisible={selectedType && askPreference}
                        answer={
                            selectedType === ContentType.MOVIE
                                ? t('home_page.desktop.want_to_search_movie_modal')
                                : t('home_page.want_to_search_show_modal')
                        }
                        cancelText={t('home_page.desktop.no_totally_random')}
                        confirmText={t('home_page.desktop.yes_use_my_preferences')}
                        onCancel={() => onUpdatePreference(false)}
                        onConfirm={() => onUpdatePreference(true)}
                    />
                );
            } else {
                return (
                    <SelectSearchTypeModal
                        isVisible={selectedType !== null}
                        onClose={() => setSelectedType(null)}
                        type={selectedType as ContentType}
                        onFeelings={(genres, feelingId) => {
                            onFeelings(genres, feelingId, selectedType);
                        }}
                        onStandard={() => {
                            onStandard(selectedType);
                        }}
                    />
                );
            }
        }
        return null;
    }, [selectedType, user, askPreference, t, onUpdatePreference, onFeelings, onStandard]);

    return (
        <div className="homeContainer">
            <Header isInDetail={false} />
            <div className="homeContainer-suggestPhrase">
                <p>{t('home_page.desktop.title_suggest')}</p>
            </div>
            <div className="homeContainer-section movieBG" style={{ borderRight: '1px solid rgba(255,255,255,0.6)' }}>
                <TypeSelectorButton
                    color={COLORS.purple}
                    text={t('home_page.desktop.movie')}
                    size={height * 0.2}
                    onClick={() => {
                        setSelectedType(ContentType.MOVIE);
                    }}
                />
            </div>
            <div className="homeContainer-section tvBG" style={{ borderLeft: '1px solid rgba(255,255,255,0.6)' }}>
                <TypeSelectorButton
                    color={COLORS.yellow}
                    text={t('home_page.desktop.tv_show')}
                    size={height * 0.2}
                    onClick={() => setSelectedType(ContentType.TV_SHOW)}
                />
            </div>
            {renderModal}
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={onCloseToast} type={success ? 'success' : 'error'} />
            <FooterSettings />
        </div>
    );
};

export default HomePageDesktop;
