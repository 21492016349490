import { FC, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../../../models/user';

import './AccountPageMobile.scss';
import AccountOverView from './Views/AccountOverView';
import { AccountView } from '../../../utilities/enum/accountView';
import PlatformsView from './Views/PlatformsView';
import GenresView from './Views/GenresView';
import ContetsListView from './Views/ContentsListView';
import SettingsView from './Views/SettingsView';
import Header from '../../../components/Header/Header';
import { ROUTES } from '../../../resources/routes-constants';

interface Props {
    user: User | null;
    onLogout: () => void;
}

const AccountPageMobile: FC<Props> = ({ user, onLogout }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [currentView, setCurrentView] = useState(AccountView.OVERVIEW);

    const handleChangeView = useCallback((view: AccountView) => {
        setCurrentView(view);
    }, []);

    const renderCurrentView = useMemo(() => {
        switch (currentView) {
            case AccountView.PLATFORMS:
                return <PlatformsView user={user} />;
            case AccountView.GENRES:
                return <GenresView user={user} />;
            case AccountView.WATCHED:
                return <ContetsListView type="watched" />;
            case AccountView.SAVED:
                return <ContetsListView type="saved" />;
            case AccountView.SETTINGS:
                return <SettingsView user={user} />;
            default:
                return <AccountOverView user={user} onLogout={onLogout} onChangeView={handleChangeView} />;
        }
    }, [currentView, handleChangeView, onLogout, user]);

    return (
        <div className={`accountMobileContainer`}>
            <Header
                isAccountPage
                isInDetail={false}
                closeAccount={() => {
                    if (currentView === AccountView.OVERVIEW) {
                        if (location.key !== 'default') {
                            navigate(-1);
                        } else {
                            navigate(ROUTES.HOME_PAGE);
                        }
                    } else {
                        setCurrentView(AccountView.OVERVIEW);
                    }
                }}
            />
            {renderCurrentView}
        </div>
    );
};

export default AccountPageMobile;
