import React, { useCallback, useMemo, useState } from 'react';
import Button from '../../../components/Button/Button';
import Title from '../../../components/Title';
import { openPrivacyPolicy, testEmail, testPassword } from '../../../utilities/functions';
import { registerUser } from '../../../api/users-requests';
import SuccessBox from '../../../components/SuccessBox';
import ErrorBox from '../../../components/ErrorBox';
import LoadingModal from '../../../components/Modals/LoadingModal';
import { useTranslation } from 'react-i18next';

export interface RegisterData {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface Props {
    onLogin: () => void;
}

const initialRegisterData = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
};

const RegisterPhase: React.FC<Props> = ({ onLogin }) => {
    const { t } = useTranslation();
    const [registerData, setRegisterData] = useState<RegisterData>(initialRegisterData);
    const [checkedPrivacy, setCheckedPrivacy] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRegister = useCallback(async () => {
        setErrors([]);
        const isEmailValid = testEmail(registerData.email);
        const isPasswordValid = testPassword(registerData.password);
        const isConfirmPasswordValid = registerData.confirmPassword === registerData.password;
        const arrayError = [];
        if (!isEmailValid) {
            arrayError.push('email');
        }
        if (!isPasswordValid) {
            arrayError.push('password');
            arrayError.push('confirmPassword');
        }
        if (!isConfirmPasswordValid) {
            arrayError.push('confirmPassword');
        }
        setErrors(arrayError);
        if (registerData.name && isEmailValid && isPasswordValid && isConfirmPasswordValid) {
            setLoading(true);
            await registerUser(registerData)
                .then((data) => {
                    if (data.user) {
                        setSuccess(t('login_page.registration_completed'));
                        setRegisterData(initialRegisterData);
                    } else {
                        setErrors(['generic_registration_error']);
                    }
                })
                .catch((error) => {
                    if (error && error.error) {
                        setErrors([error.error]);
                    } else {
                        setErrors(['generic_registration_error']);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [registerData, t]);

    const handleChangePrivacy = useCallback(() => {
        setCheckedPrivacy(!checkedPrivacy);
    }, [checkedPrivacy]);

    const errorMessage: string = useMemo(() => {
        if (errors.includes('email') || errors.includes('password') || errors.includes('confirmPassword')) {
            let message = t('errors.check_following_field_registration');
            if (errors.includes('email')) {
                const indexFound = errors.findIndex((text) => text === t('login_page.email'));
                message += indexFound + 1 === errors.length ? t('login_page.email') : `${t('login_page.email')}, `;
            }
            if (errors.includes('password')) {
                const indexFound = errors.findIndex((text) => text === t('login_page.password'));
                message +=
                    indexFound + 1 === errors.length ? t('login_page.password') : `${t('login_page.password')}, `;
            }
            if (errors.includes('confirmPassword')) {
                const indexFound = errors.findIndex((text) => text === t('login_page.password_confirm'));
                message +=
                    indexFound + 1 === errors.length
                        ? t('login_page.password_confirm')
                        : `${t('login_page.password_confirm')}, `;
            }
            return message;
        }
        if (errors.length > 0) {
            if (errors[0] === 'email_already_exists') {
                return t('errors.email_already_exists');
            } else {
                return t(`errors.${errors[0]}`) ?? t('errors.generic_registration_error');
            }
        }
        return '';
    }, [errors, t]);

    const disableButton = useMemo(() => {
        const isEmailValid = testEmail(registerData.email);
        const isPasswordValid = testPassword(registerData.password);
        const isConfirmPasswordValid = registerData.confirmPassword === registerData.password;
        if (!registerData.name) {
            return true;
        }
        if (!isEmailValid) {
            return true;
        }
        if (!isPasswordValid) {
            return true;
        }
        if (!isConfirmPasswordValid) {
            return true;
        }
        if (!checkedPrivacy) {
            return true;
        }
        return false;
    }, [registerData, checkedPrivacy]);

    return (
        <form onSubmit={handleRegister} className="loginContainer-content_formColumn__columnContent">
            <Title text="Registrati a WhatWatch" align="left" weight="bold" />
            <form>
                <input
                    type="text"
                    value={registerData.name}
                    onChange={(e) => setRegisterData({ ...registerData, name: e.target.value })}
                    placeholder={t('login_page.name')}
                />
                <input
                    type="email"
                    value={registerData.email}
                    onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
                    placeholder={t('login_page.email')}
                />
                <input
                    type="password"
                    value={registerData.password}
                    onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
                    placeholder={t('login_page.password')}
                />
                <input
                    type="password"
                    value={registerData.confirmPassword}
                    onChange={(e) => setRegisterData({ ...registerData, confirmPassword: e.target.value })}
                    placeholder={t('login_page.password_confirm')}
                />
                <div className="loginContainer-content_formColumn__columnContent--checkPassword">
                    <input type="checkbox" required onChange={handleChangePrivacy} />
                    <div className="description">
                        {t('login_page.read_privacy')}{' '}
                        <Button
                            text={t('login_page.privacy_policy')}
                            onClick={openPrivacyPolicy}
                            styleClass="linkPurple"
                        />
                    </div>
                </div>

                {success ? (
                    <SuccessBox message={success} />
                ) : (
                    <>
                        {errors.length > 0 ? <ErrorBox message={errorMessage} /> : null}
                        <Button text={t('login_page.signup')} onClick={handleRegister} disabled={disableButton} />
                    </>
                )}
            </form>

            <Title text={t('login_page.have_an_account')} align="left" weight="bold" />
            <Button
                text={t('login_page.signin_now')}
                onClick={() => {
                    setRegisterData(initialRegisterData);
                    onLogin();
                }}
            />
            <LoadingModal isVisible={loading} />
        </form>
    );
};

export default RegisterPhase;
