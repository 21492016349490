import { Filter, Genre, Provider } from '../../../models/data';
import { User } from '../../../models/user';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ContentType } from '../../../utilities/enum/contentType';
import Header from '../../../components/Header/Header';
import { initialFilter } from '../FilterPage';
import ChooseTypeStep from './Steps/ChooseTypeStep';
import ChoosePlatformsStep from './Steps/ChoosePlatformsStep';
import ChooseGenresStep from './Steps/ChooseGenresStep';
import ChooseMetaStep from './Steps/ChooseMetaStep';
import { ReducerData, Reducers } from '../../../models/reducers';
import { getUserProvidersArray, getUserGenresArray } from '../../../utilities/functions';
import { useSelector } from 'react-redux';
import './FilterPageMobile.scss';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Toast from '../../../components/Toast';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import { useTranslation } from 'react-i18next';

enum FilterSteps {
    TYPE,
    PLATFORMS,
    GENRES,
    META,
}

interface Props {
    user: User | null;
    success: string;
    error: string;
    loading: boolean;
    onGoBack: () => void;
    onSearch: (filter: Filter, type: ContentType) => void;
    onSetPreferences: (filter: Filter) => void;
    onCloseToast: () => void;
    onGoToLogin: () => void;
}

const FilterPage: FC<Props> = ({
    user,
    success,
    error,
    loading,
    onGoBack,
    onSearch,
    onSetPreferences,
    onCloseToast,
    onGoToLogin,
}) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [currentStep, setCurrentStep] = useState(FilterSteps.TYPE);
    const [contentType, setContentType] = useState<ContentType | null>(null);
    const [filter, setFilter] = useState<Filter>(initialFilter);
    const [loginModal, setLoginModal] = useState(false);

    const convertPlatformsInProviders = useMemo(() => {
        if (user && user.platforms) {
            const providers = getUserProvidersArray(user.platforms, data.movieProvider, data.showProvider);
            return providers;
        } else {
            return [];
        }
    }, [data.movieProvider, data.showProvider, user]);

    const startFilter = useMemo(() => {
        if (user) {
            const genres = getUserGenresArray(user.genres, data.movieGenres, data.showGenres);
            return {
                ...initialFilter,
                genres: genres,
                providers: convertPlatformsInProviders,
            };
        } else {
            return initialFilter;
        }
    }, [convertPlatformsInProviders, data.movieGenres, data.showGenres, user]);

    const handleSelectType = useCallback((type: ContentType) => {
        setContentType(type);
        setCurrentStep(FilterSteps.PLATFORMS);
    }, []);

    const handleSelectPlatforms = useCallback(
        (providers: Provider[]) => {
            setFilter({ ...filter, providers: providers });
            setCurrentStep(FilterSteps.GENRES);
        },
        [filter],
    );

    const handleSelectGenres = useCallback(
        (genres: Genre[]) => {
            setFilter({ ...filter, genres: genres });
            setCurrentStep(FilterSteps.META);
        },
        [filter],
    );

    const handleChangeDates = useCallback(
        (startDate: string, endDate: string) => {
            setFilter({ ...filter, startDate, endDate });
        },
        [filter],
    );

    const handleSavePreferences = useCallback(() => {
        if (user) {
            onSetPreferences(filter);
        } else {
            setLoginModal(true);
        }
    }, [filter, onSetPreferences, user]);

    const handleSearch = useCallback(() => {
        if (contentType) {
            onSearch(filter, contentType);
        }
    }, [contentType, filter, onSearch]);

    const handleOnBack = useCallback(() => {
        switch (currentStep) {
            case FilterSteps.PLATFORMS:
                setFilter({ ...filter, providers: startFilter.providers });
                setCurrentStep(FilterSteps.TYPE);
                break;
            case FilterSteps.GENRES:
                setFilter({ ...filter, genres: startFilter.genres });
                setCurrentStep(FilterSteps.PLATFORMS);
                break;
            case FilterSteps.META:
                setFilter({ ...filter, startDate: initialFilter.startDate, endDate: initialFilter.endDate });
                setCurrentStep(FilterSteps.GENRES);
                break;
            default:
                break;
        }
    }, [currentStep, filter, startFilter]);

    const renderCurrentStep = useMemo(() => {
        switch (currentStep) {
            case FilterSteps.PLATFORMS:
                if (contentType) {
                    return (
                        <ChoosePlatformsStep
                            userProviders={startFilter.providers}
                            type={contentType}
                            onNext={handleSelectPlatforms}
                            onBack={handleOnBack}
                        />
                    );
                }
                return;
            case FilterSteps.GENRES:
                if (contentType) {
                    return (
                        <ChooseGenresStep
                            userGenres={startFilter.genres}
                            type={contentType}
                            onNext={handleSelectGenres}
                            onBack={handleOnBack}
                        />
                    );
                }
                return;
            case FilterSteps.META:
                if (contentType) {
                    return (
                        <ChooseMetaStep
                            type={contentType}
                            startDate={filter.startDate}
                            endDate={filter.endDate}
                            onChange={handleChangeDates}
                            onBack={handleOnBack}
                            onFinish={handleSearch}
                            onSave={handleSavePreferences}
                        />
                    );
                }
                return;
            default:
                return <ChooseTypeStep onNext={handleSelectType} />;
        }
    }, [
        currentStep,
        contentType,
        handleSelectType,
        startFilter.providers,
        startFilter.genres,
        handleSelectPlatforms,
        handleOnBack,
        handleSelectGenres,
        filter.startDate,
        filter.endDate,
        handleChangeDates,
        handleSearch,
        handleSavePreferences,
    ]);

    useEffect(() => {
        setFilter(startFilter);
    }, [startFilter]);

    return (
        <div className="filterContainer">
            <Header isInDetail={false} closeAccount={onGoBack} isFilterPage />
            <div className="filterContainer-content">{renderCurrentStep}</div>
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={onCloseToast} type={success ? 'success' : 'error'} />
            <ConfirmModal
                isVisible={loginModal}
                answer={t('filter_page.mobile.login_modal')}
                confirmText={t('filter_page.mobile.login_modal_confirm')}
                cancelText={t('filter_page.mobile.login_modal_cancel')}
                onCancel={() => setLoginModal(false)}
                onConfirm={onGoToLogin}
            />
        </div>
    );
};

export default FilterPage;
