import { FC, useMemo, useState } from 'react';
import { Movie, TvShow } from '../../../models/data';
import Header from '../../../components/Header/Header';
import Loader from '../../../components/Loader/Loader';
import Icon from '../../../components/Icon/Icon';
import useWindowDimensions from '../../../utilities/hooks';
import FooterSettings from '../../../components/FooterSettings/FooterSettings';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Toast from '../../../components/Toast';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import ButtonIcon from '../../../components/ButtonIcon';
import { User } from '../../../models/user';
import LikePopover from '../../../components/Popovers/LikePopover';
import { COLORS } from '../../../resources/constants';
import { useTranslation } from 'react-i18next';
import { ContentType } from '../../../utilities/enum/contentType';

import './ResultPageDesktop.scss';
import JustWatchReference from '../../../components/JustWatchReference/JustWatchReference';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

enum Part {
    UP,
    DOWN,
}

interface Props {
    content: Movie | TvShow | null;
    type: ContentType;
    removeContent: boolean;
    isWatched: boolean;
    isInWatchList: boolean;
    isLike: boolean;
    user: User | null;
    loadingContent: boolean;
    success: string;
    error: string;
    loading: boolean;
    onRemoveContent: () => void;
    onCancelDelete: () => void;
    onConfirmDelete: () => void;
    onOtherContentClick: (type: ContentType) => void;
    onSaveContent: () => void;
    onAddToWatched: (isLike: boolean) => void;
    onChangeLike: () => void;
    onCloseToast: () => void;
}

const ResultPageDesktop: FC<Props> = ({
    content,
    type,
    removeContent,
    isWatched,
    isInWatchList,
    isLike,
    user,
    success,
    error,
    loadingContent,
    loading,
    onRemoveContent,
    onCancelDelete,
    onConfirmDelete,
    onOtherContentClick,
    onSaveContent,
    onAddToWatched,
    onChangeLike,
    onCloseToast,
}) => {
    const { t } = useTranslation();
    const [currentPart, setCurrentPart] = useState(Part.UP);
    const { width } = useWindowDimensions();
    const [likeAction, setLikeAction] = useState<HTMLDivElement | null>(null);
    const { contentId } = useParams();

    const renderChevron = useMemo(() => {
        return (
            <div className="resultContainer-content--indicators">
                <div
                    className="resultContainer-content--indicators_up"
                    style={{ opacity: currentPart === Part.UP ? 0.6 : 1 }}
                    onClick={() => {
                        if (currentPart === Part.DOWN) {
                            setCurrentPart(Part.UP);
                        }
                    }}
                >
                    <Icon name={'chevron-up'} size={width * 0.008} color="#fff" />
                </div>
                <div
                    className="resultContainer-content--indicators_down"
                    style={{ opacity: currentPart === Part.DOWN ? 0.6 : 1 }}
                    onClick={() => {
                        if (currentPart === Part.UP) {
                            setCurrentPart(Part.DOWN);
                        }
                    }}
                >
                    <Icon name={'chevron-down'} size={width * 0.008} color="#fff" />
                </div>
            </div>
        );
    }, [currentPart, width]);

    const renderWatchlistIcon = useMemo(() => {
        if (!isWatched) {
            if (isInWatchList) {
                return (
                    <ButtonIcon
                        icon="remove"
                        onClick={onRemoveContent}
                        size={width * 0.02}
                        tooltip={`${t('result_page.desktop.do_you_remove')} ${
                            type === ContentType.MOVIE
                                ? t('result_page.desktop.this_movie')
                                : t('result_page.desktop.this_tv_show')
                        } ${t('result_page.desktop.from_watchlist')}?`}
                    />
                );
            }
            return (
                <ButtonIcon
                    icon="watchlist"
                    onClick={onSaveContent}
                    size={width * 0.02}
                    tooltip={
                        user
                            ? `${t('result_page.desktop.do_you_add')} ${
                                  type === ContentType.MOVIE
                                      ? t('result_page.desktop.this_movie')
                                      : t('result_page.desktop.this_tv_show')
                              } ${t('result_page.desktop.to_watchlist')}?`
                            : `${t('result_page.desktop.signin_to_add')} ${
                                  type === ContentType.MOVIE
                                      ? t('result_page.desktop.this_movie')
                                      : t('result_page.desktop.this_tv_show')
                              }`
                    }
                />
            );
        } else {
            return null;
        }
    }, [isWatched, isInWatchList, onSaveContent, width, user, t, type, onRemoveContent]);

    const renderWatchedIcon = useMemo(() => {
        if (content) {
            if (isWatched) {
                return (
                    <div>
                        <ButtonIcon
                            icon={isLike ? 'like' : 'dislike'}
                            tooltip={`${t('result_page.desktop.change_like')} ${
                                type === ContentType.MOVIE
                                    ? t('result_page.desktop.this_movie')
                                    : t('result_page.desktop.this_tv_show')
                            }`}
                            onClick={onChangeLike}
                            size={width * 0.02}
                        />
                    </div>
                );
            }
            return (
                <>
                    <ButtonIcon
                        icon={'favorite'}
                        tooltip={
                            user
                                ? `${t('result_page.desktop.do_you_like')} ${
                                      type === ContentType.MOVIE
                                          ? t('result_page.desktop.this_movie')
                                          : t('result_page.desktop.this_tv_show')
                                  }?`
                                : `${t('result_page.desktop.signin_to_add')} ${
                                      type === ContentType.MOVIE
                                          ? t('result_page.desktop.this_movie')
                                          : t('result_page.desktop.this_tv_show')
                                  }`
                        }
                        onClick={(target) => {
                            if (user) {
                                setLikeAction(target);
                            }
                        }}
                        size={width * 0.02}
                    />
                    <LikePopover
                        element={likeAction}
                        onClose={() => setLikeAction(null)}
                        onAdd={(isLike) => {
                            onAddToWatched(isLike);
                            setLikeAction(null);
                        }}
                    />
                </>
            );
        }
        return null;
    }, [content, isWatched, user, t, type, width, likeAction, isLike, onChangeLike, onAddToWatched]);

    const renderStars = useMemo(() => {
        if (content && content.voteAverage) {
            const val = Math.round(content.voteAverage / 2);
            const arrayStars = [];
            for (let i = 0; i < 5; i++) {
                if (i < val) {
                    arrayStars.push(<Icon key={i} name="star-fill" color={COLORS.yellow} size={width * 0.01} />);
                } else {
                    arrayStars.push(<Icon key={i} name="star-void" color={'#52565A'} size={width * 0.01} />);
                }
            }
            return <div className="resultContainer-content--contentData_fixedData__stars">{arrayStars}</div>;
        }
        return null;
    }, [content, width]);

    const renderPlatforms = useMemo(() => {
        if (content && content.providers && content.providers.length > 0) {
            const uniqueProviders = content.providers.filter(
                (provider, index, self) => index === self.findIndex((o) => o.id === provider.id),
            );
            return (
                <div className="resultContainer-content--contentData_parts__up-platforms">
                    <span>{t('result_page.desktop.watch_now')}:</span>
                    <div className="resultContainer-content--contentData_parts__up-platforms--list">
                        {uniqueProviders.map((p) => {
                            return <img src={p.logo} key={`resultPageDesktopProvider${p.id}`} alt={p.name} />;
                        })}
                    </div>
                    <JustWatchReference />
                </div>
            );
        }
        return null;
    }, [content, t]);

    const renderTrailer = useMemo(() => {
        if (content && content.trailer) {
            return (
                <div className="resultContainer-content--contentData_parts__up-trailer">
                    <span>{t('result_page.desktop.trailer')}:</span>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => window.open(`${content.trailer as string}`, '_blank')}
                    >
                        {t('result_page.desktop.watch_trailer')}
                    </span>
                </div>
            );
        }
        return null;
    }, [content, t]);

    const renderDirector = useMemo(() => {
        if (content && content.director) {
            return (
                <div className="resultContainer-content--contentData_parts__up-director">
                    <span>{t('result_page.desktop.directed_by')}:</span>
                    <span>{content.director.name}</span>
                </div>
            );
        }
        return null;
    }, [content, t]);

    const renderCast = useMemo(() => {
        if (content && content.cast && content.cast.length > 0) {
            const shortCast = content.cast.length > 5 ? content.cast.slice(0, 5) : content.cast;
            return (
                <div className="resultContainer-content--contentData_parts__up-cast">
                    <span>{t('result_page.desktop.cast')}:</span>
                    <p>
                        {shortCast.map((actor, index) => {
                            return (
                                <span key={`actor${actor.id}`}>
                                    {actor.name}
                                    {index < shortCast.length - 1 ? ', ' : ''}
                                </span>
                            );
                        })}
                    </p>
                </div>
            );
        }
        return null;
    }, [content, t]);

    const renderDate = useMemo(() => {
        if (content && content.releaseDate) {
            const date = new Date(content.releaseDate);
            return (
                <div className="resultContainer-content--contentData_parts__up-lastData--date">
                    <span>{t('result_page.desktop.release_date')}:</span>
                    <span>{format(date, 'dd-MM-yyyy')}</span>
                </div>
            );
        }
        return null;
    }, [content, t]);

    const renderDuration = useMemo(() => {
        if (content) {
            if ((content as Movie).runtime) {
                return (
                    <div className="resultContainer-content--contentData_parts__up-lastData--duration">
                        <span>{t('result_page.desktop.duration')}:</span>
                        <span>
                            {(content as Movie).runtime} {t('result_page.desktop.min')}
                        </span>
                    </div>
                );
            }
            if ((content as TvShow).episodes) {
                return (
                    <div className="resultContainer-content--contentData_parts__up-lastData--duration">
                        <span>{t('result_page.desktop.number_episodes')}:</span>
                        <span>{(content as TvShow).episodes}</span>
                    </div>
                );
            }
        }
        return null;
    }, [content, t]);

    const renderCover = useMemo(() => {
        if (content) {
            return (
                <div className="resultContainer-content--cover">
                    <div
                        className="resultContainer-content--cover_signalRow"
                        style={{
                            backgroundColor: type === ContentType.MOVIE ? COLORS.purple : COLORS.yellow,
                        }}
                    />
                    <div className="resultContainer-content--cover_imgContainer">
                        <img src={content.poster as string} alt={content.name} />
                        <div className="resultContainer-content--cover_reactions">
                            <div className="resultContainer-content--cover_reactions__like">{renderWatchedIcon}</div>
                            {renderWatchlistIcon}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    }, [content, type, renderWatchedIcon, renderWatchlistIcon]);

    const renderContent = useMemo(() => {
        if (content) {
            return (
                <div className="resultContainer-content--contentData">
                    <div className="resultContainer-content--contentData_titleSection">
                        <h2 className="resultContainer-content--contentData_titleSection__title">{content.name}</h2>
                        <Icon name="share" color="#fff" size={width * 0.01} />
                    </div>
                    <div className="resultContainer-content--contentData_fixedData">
                        {content.genres.length > 0 ? (
                            <p className="resultContainer-content--contentData_fixedData__genres">
                                {content.genres.map((g, i) => {
                                    return (
                                        <span key={`genre${g.id}`}>
                                            {g.name} {i < content.genres.length - 1 ? ' / ' : ''}
                                        </span>
                                    );
                                })}
                            </p>
                        ) : null}
                        {renderStars}
                    </div>
                    <div className="resultContainer-content--contentData_parts">
                        {currentPart === Part.UP ? (
                            <div className="resultContainer-content--contentData_parts__up">
                                {renderTrailer}
                                {renderPlatforms}
                                {renderDirector}
                                {renderCast}
                                <div className="resultContainer-content--contentData_parts__up-lastData">
                                    {renderDate}
                                    {renderDuration}
                                </div>
                            </div>
                        ) : (
                            <div className="resultContainer-content--contentData_parts__down">
                                <div className="resultContainer-content--contentData_parts__down-plot">
                                    <span>{t('result_page.desktop.plot')}:</span>
                                    <span>{content.overview}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    }, [
        content,
        currentPart,
        renderCast,
        renderDate,
        renderDirector,
        renderDuration,
        renderPlatforms,
        renderStars,
        renderTrailer,
        t,
        width,
    ]);

    if (!loadingContent && !content) {
        return (
            <div className={`resultContainer ${type}BG`}>
                <div className="resultContainer-notFound">
                    <p style={{ fontSize: 20, color: '#fff', textAlign: 'center' }}>
                        {t('result_page.desktop.no_content_found')}
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div className={`resultContainer ${type}BG`}>
            <Header
                isInDetail={loadingContent ? false : true}
                type={type}
                otherFilmClick={() => onOtherContentClick(ContentType.MOVIE)}
                otherShowClick={() => onOtherContentClick(ContentType.TV_SHOW)}
                hideOtherSearchButtons={contentId ? true : false}
            />
            {loadingContent ? (
                <div className="resultContainer-loading">
                    <Loader type={type ? type : ''} />
                </div>
            ) : (
                <div className="resultContainer-content">
                    {renderChevron}
                    {renderCover}
                    {renderContent}
                </div>
            )}
            <FooterSettings />
            {loading && content ? <LoadingModal isVisible={loading} /> : null}
            <Toast message={error || success} onClose={onCloseToast} type={success ? 'success' : 'error'} />
            <ConfirmModal
                isVisible={removeContent}
                answer={t('result_page.desktop.do_you_want_to_remove_content')}
                cancelText={t('result_page.desktop.do_you_want_to_remove_content_no')}
                confirmText={t('result_page.desktop.do_you_want_to_remove_content_yes')}
                onCancel={onCancelDelete}
                onConfirm={onConfirmDelete}
            />
        </div>
    );
};

export default ResultPageDesktop;
