import React, { CSSProperties } from 'react';
import Icon from '../Icon/Icon';

import './Button.scss';

interface Props {
    disabled?: boolean;
    styleClass?:
        | 'gradientBG'
        | 'revertGradientBG'
        | 'solidYellow'
        | 'solidPurple'
        | 'linkYellow'
        | 'linkPurple'
        | 'linkDelete'
        | 'linkOpacity';
    style?: CSSProperties;
    text: string;
    onClick: (event?: any) => void;
    icon?: string;
    iconColor?: string;
    isUppercase?: boolean;
}

const Button: React.FC<Props> = ({
    disabled = false,
    styleClass = 'gradientBG',
    style = {},
    text,
    onClick,
    icon = '',
    iconColor = '#fff',
    isUppercase,
}) => {
    return (
        <div
            style={{
                ...style,
            }}
            className={`button ${styleClass.includes('link') ? '-link' : ''} -${styleClass} ${
                disabled ? '-disabled' : ''
            } `}
            onClick={(event) => {
                if (!disabled) {
                    onClick(event);
                }
            }}
        >
            {icon ? <Icon name={icon} size={18} color={iconColor} /> : null}
            <p className={isUppercase ? '-uppercase' : ''}>{text}</p>
        </div>
    );
};

export default Button;
