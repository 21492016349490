import Header from '../../../components/Header/Header';
import { ReducerData, Reducers } from '../../../models/reducers';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Feeling, Genre } from '../../../models/data';
import FeelingItem from '../../../components/FeelingItem/FeelingItem';
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../resources/routes-constants';
import ChooseTypeModal from '../../../components/Modals/ChooseTypeModal';
import LoadingModal from '../../../components/Modals/LoadingModal';
import { ContentType } from '../../../utilities/enum/contentType';

import './FeelingsPageMobile.scss';

interface Props {
    onSelect: (genres: Genre[], feelingId: number, selectedType: ContentType) => void;
}

const FeelingsPageMobile: FC<Props> = ({ onSelect }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [selectedFeeling, setSelectedFeeling] = useState<Feeling | null>(null);

    const handleFeeling = useCallback(
        (type: ContentType) => {
            if (selectedFeeling) {
                const genres =
                    type === ContentType.MOVIE
                        ? data.movieGenres.filter(
                              (genre) => selectedFeeling.movieGenres.find((g) => g === genre.id) !== undefined,
                          )
                        : data.showGenres.filter(
                              (genre) => selectedFeeling.tvGenres.find((g) => g === genre.id) !== undefined,
                          );
                onSelect(genres, selectedFeeling.id, type);
            }
        },
        [data.movieGenres, data.showGenres, onSelect, selectedFeeling],
    );

    return (
        <div className="feelingsPageContainer">
            <Header isInDetail={false} />
            <h2 className="feelingsPageContainer-title">{t('feeling_page.mobile.title')}</h2>
            <div className="feelingsPageContainer-feelingsGrid">
                {data.feelings.map((feeling) => {
                    return (
                        <FeelingItem
                            key={`feeling${feeling.id}`}
                            feeling={feeling}
                            onClick={() => {
                                setSelectedFeeling(feeling);
                            }}
                        />
                    );
                })}
            </div>
            <div className="homeContainer-titleButtonSection">
                <h3>{t('feeling_page.mobile.or')}</h3>
                <Button
                    onClick={() => {
                        navigate(ROUTES.FILTER_PAGE);
                    }}
                    text={t('feelings_page.mobile.filters_button')}
                />
            </div>
            <ChooseTypeModal isVisible={selectedFeeling != null} onContentSelect={handleFeeling} />
            <LoadingModal isVisible={data.feelings.length === 0} />
        </div>
    );
};

export default FeelingsPageMobile;
