import { ReduxAction } from '../../models/reducers';
import { SavedType, User, WatchedType } from '../../models/user';

export enum USER_ACTIONS {
    SET_USER = 'userActions/login',
    SET_SAVED_MOVIES = 'userActions/setSavedMovies',
    SET_SAVED_SHOWS = 'userActions/setSavedShows',
    SET_WATCHED_MOVIES = 'userActions/setWatchedMovies',
    SET_WATCHED_SHOWS = 'userActions/setWatchedShows',
    SET_ASK_PREFERENCE = 'userActions/setAskPreference',
    SET_IS_INSTALLABLE_PWA = 'userActions/setIsInstallablePWA',
    LOGOUT = 'userActions/logout',
}

export const setUser: ReduxAction<User> = (user: User) => {
    return {
        type: USER_ACTIONS.SET_USER,
        payload: user,
    };
};

export const setSavedMovies: ReduxAction<SavedType[]> = (contents: SavedType[]) => {
    return {
        type: USER_ACTIONS.SET_SAVED_MOVIES,
        payload: contents,
    };
};

export const setSavedShows: ReduxAction<SavedType[]> = (contents: SavedType[]) => {
    return {
        type: USER_ACTIONS.SET_SAVED_SHOWS,
        payload: contents,
    };
};

export const setWatchedMovies: ReduxAction<WatchedType[]> = (contents: WatchedType[]) => {
    return {
        type: USER_ACTIONS.SET_WATCHED_MOVIES,
        payload: contents,
    };
};

export const setWatchedShows: ReduxAction<WatchedType[]> = (contents: WatchedType[]) => {
    return {
        type: USER_ACTIONS.SET_WATCHED_SHOWS,
        payload: contents,
    };
};

export const setAskPreference: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: USER_ACTIONS.SET_ASK_PREFERENCE,
        payload: status,
    };
};

export const setIsInstallablePwa: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: USER_ACTIONS.SET_IS_INSTALLABLE_PWA,
        payload: status,
    };
};

export const logoutUser = () => {
    return {
        type: USER_ACTIONS.LOGOUT,
    };
};
