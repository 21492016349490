import Button from '../../../../components/Button/Button';
import FilterStepQuestion from '../../../../components/FilterStepQuestion/FilterStepQuestion';
import YearRangeSelector from '../../../../components/YearRangeSelector/YearRangeSelector';
import { ContentType } from '../../../../utilities/enum/contentType';
import { format, getYear } from 'date-fns';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    type: ContentType;
    startDate: string;
    endDate: string;
    onChange: (startDate: string, endDate: string) => void;
    onFinish: () => void;
    onSave: () => void;
    onBack: () => void;
}

const ChooseMetaStep: FC<Props> = ({ type, startDate, endDate, onChange, onFinish, onSave, onBack }) => {
    const { t } = useTranslation();

    const yearStart = useMemo(() => {
        return getYear(new Date(startDate));
    }, [startDate]);

    const yearEnd = useMemo(() => {
        return getYear(new Date(endDate));
    }, [endDate]);

    return (
        <div className="filterContainer-content_step -meta">
            <div className="filterContainer-content_step__flex1">
                <FilterStepQuestion number={4} text={t('filter_page.mobile.choose_years')} />
                <h3 className="filterContainer-content_step__range">
                    {t('filter_page.mobile.years_range', { startDate: yearStart, endDate: yearEnd })}
                </h3>
                <YearRangeSelector
                    orientation="horizontal"
                    selectedRange={[yearStart, yearEnd]}
                    type={type}
                    onChange={(values) => {
                        if (values.length > 1) {
                            const end =
                                values[1] < getYear(new Date())
                                    ? `${values[1]}-12-31`
                                    : format(new Date(), 'yyyy-MM-dd');
                            onChange(`${values[0]}-01-01`, end);
                        }
                    }}
                />
            </div>
            <Button text={t('filter_page.mobile.start_search')} onClick={onFinish} />
            <Button text={t('filter_page.mobile.save_preferences')} onClick={onSave} styleClass="revertGradientBG" />
            <Button text={t('filter_page.mobile.previous_step')} onClick={onBack} styleClass="linkOpacity" />
        </div>
    );
};

export default ChooseMetaStep;
