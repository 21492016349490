import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../resources/routes-constants';
import { ReducerUser, Reducers } from '../../models/reducers';
import { parse } from 'qs';
import { testPassword } from '../../utilities/functions';
import { resetPassowrd } from '../../api/users-requests';
import { ResultType } from '../../models/user';
import ViewportSwitchComponent from '../../components/ViewportSwitchComponent';
import ResetPasswordPageDesktop from './Desktop/ResetPasswordPageDesktop';

export interface Password {
    password: string;
    confirmPassword: string;
}

export interface ResetPassword {
    resetPasswordToken: string;
    password: string;
}

const ResetPasswordPage: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState<Password>({
        password: '',
        confirmPassword: '',
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (user.user) {
            navigate(ROUTES.ACCOUNT_PAGE);
        }

        const params = parse(window.location.search, {
            ignoreQueryPrefix: true,
        });
        if (params && params.token) {
            setToken(params.token as string);
        } else {
            navigate(ROUTES.LOGIN_PAGE);
        }
    }, [navigate, user.user]);

    const handleResetPassword = useCallback(async () => {
        setLoading(true);
        const isPasswordValid = testPassword(newPassword.password);
        const isConfirmPasswordValid = newPassword.confirmPassword === newPassword.password;
        const errorsArray = [];
        if (!isPasswordValid) {
            errorsArray.push('password');
        }
        if (!isConfirmPasswordValid) {
            errorsArray.push('confirmPassword');
        }
        setErrors(errorsArray);
        if (isPasswordValid && token && isConfirmPasswordValid) {
            await resetPassowrd({ resetPasswordToken: token, password: newPassword.password })
                .then((data) => {
                    if ((data as ResultType).status === 'ok') {
                        setSuccess('Cambio password effettuato correttamente');
                    }
                })
                .catch((error) => {
                    if (error.error) {
                        setErrors([error.error]);
                    } else {
                        setErrors([error.toString()]);
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [token, newPassword]);

    const handleBackToLogin = useCallback(() => {
        if (success) {
            navigate(ROUTES.LOGIN_PAGE);
        }
    }, [navigate, success]);

    return (
        <ViewportSwitchComponent
            desktopComponent={
                <ResetPasswordPageDesktop
                    newPassword={newPassword}
                    token={token}
                    loading={loading}
                    errors={errors}
                    success={success}
                    onUpdatePassword={setNewPassword}
                    onBackToLogin={handleBackToLogin}
                    onResetPassword={handleResetPassword}
                />
            }
            mobileComponent={
                <ResetPasswordPageDesktop
                    newPassword={newPassword}
                    token={token}
                    loading={loading}
                    errors={errors}
                    success={success}
                    onUpdatePassword={setNewPassword}
                    onBackToLogin={handleBackToLogin}
                    onResetPassword={handleResetPassword}
                />
            }
        />
    );
};

export default ResetPasswordPage;
