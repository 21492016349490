import React, { useMemo, useState } from 'react';
import Button from '../../../components/Button/Button';
import ErrorBox from '../../../components/ErrorBox';
import LoadingModal from '../../../components/Modals/LoadingModal';
import Paragraph from '../../../components/Paragraph';
import SuccessBox from '../../../components/SuccessBox';
import Title from '../../../components/Title';
import { sendResetPasswordEmail } from '../../../api/users-requests';
import { testEmail } from '../../../utilities/functions';
import { useTranslation } from 'react-i18next';

interface Props {
    onBack: () => void;
}

const ResetPasswordPhase: React.FC<Props> = ({ onBack }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleResetMail = async () => {
        if (email && testEmail(email)) {
            setError('');
            setLoading(true);
            try {
                await sendResetPasswordEmail(email);
                setEmail('');
                setSuccess(t('login_page.reset_password_email_sent'));
            } catch (error: any) {
                console.log(error);
                setError(t(`errors.${error.error}`) ?? t('errors.generic_reset_password_error'));
            }
            setLoading(false);
        }
    };

    const disableButton = useMemo(() => {
        if (testEmail(email)) {
            return false;
        }
        return true;
    }, [email]);

    return (
        <div className="loginContainer-content_formColumn__columnContent">
            <Title text={t('login_page.reset_password_title')} align="left" weight="bold" />
            <Paragraph>
                <p>{t('login_page.reset_password_paragraph')}</p>
            </Paragraph>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('login_page.email')}
            />
            {error ? <ErrorBox message={error} /> : null}
            {!success ? (
                <Button text={t('login_page.send_email')} disabled={disableButton} onClick={handleResetMail} />
            ) : (
                <SuccessBox message={success} />
            )}
            <Paragraph>
                <p onClick={onBack} style={{ color: '#fff', cursor: 'pointer' }}>
                    ← {t('login_page.back_to_login')}
                </p>
            </Paragraph>
            <LoadingModal isVisible={loading} />
        </div>
    );
};

export default ResetPasswordPhase;
