import React, { CSSProperties } from 'react';
import Icon from './Icon/Icon';

interface Props {
    icon: string;
    size: number;
    onClick: (event: HTMLDivElement) => void;
    tooltip?: string;
    style?: CSSProperties;
}

const ButtonIcon: React.FC<Props> = ({ icon, style, size, onClick, tooltip = '' }) => {
    const handleClick = (event: React.MouseEvent) => {
        if (event.currentTarget) {
            onClick(event.currentTarget as HTMLDivElement);
        } else {
            onClick(document.createElement('div'));
        }
    };

    return (
        <div
            className="gradientBG tooltip"
            style={{
                width: size,
                height: size,
                borderRadius: size,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                ...style,
            }}
            onClick={handleClick}
        >
            <Icon name={icon} size={size * 0.6} color="#fff" style={{ marginTop: icon === 'remove' ? -2 : 0 }} />
            {tooltip ? <p className="tooltipText">{tooltip}</p> : null}
        </div>
    );
};

export default ButtonIcon;
