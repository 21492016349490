import { editUser } from '../../../../api/users-requests';
import Button from '../../../../components/Button/Button';
import ChangeType from '../../../../components/ChangeType/ChangeType';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import MultiFilterSelector from '../../../../components/MultiFilterSelector/MultiFilterSelector';
import Toast from '../../../../components/Toast';
import { Provider } from '../../../../models/data';
import { ReducerData, Reducers } from '../../../../models/reducers';
import { User } from '../../../../models/user';
import { setUser } from '../../../../store/actions/user';
import { ContentType } from '../../../../utilities/enum/contentType';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

interface Props {
    user: User | null;
}

const PlatformsView: FC<Props> = ({ user }) => {
    const { t } = useTranslation();
    const data: ReducerData = useSelector((store: Reducers) => store.data);
    const [selectedType, setSelectedType] = useState<ContentType>(ContentType.MOVIE);
    const [selectedPlatforms, setSelectedPlatforms] = useState(user?.platforms ?? []);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const dispatch = useDispatch();

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    const handleChangeArray = useCallback(
        (platforms: Provider[]) => {
            const arrayIds = [...selectedPlatforms];
            for (const platform of platforms) {
                arrayIds.push(platform.id);
            }
            const arrayUniqueIds = [...new Set(arrayIds)];
            setSelectedPlatforms(arrayUniqueIds);
        },
        [selectedPlatforms],
    );

    const handleSave = useCallback(() => {
        if (user) {
            setLoading(true);
            editUser(user.id, { platforms: selectedPlatforms, genres: user.genres })
                .then((response) => {
                    if ((response as User).id && user) {
                        dispatch(
                            setUser({
                                ...user,
                                platforms: selectedPlatforms,
                            }),
                        );
                        setSuccess(t(`account_page.desktop.preferences.preferences_saved`));
                    } else {
                        setError(t(`errors.save_preferences_error`));
                    }
                })
                .catch((error) => setError(t(`errors.${error.error}` ?? t(`errors.save_preferences_error`))))
                .finally(() => setLoading(false));
        }
    }, [dispatch, selectedPlatforms, t, user]);

    const selectedProviders = useMemo(() => {
        const arraySelected: Provider[] = [];
        if (user) {
            if (selectedType === ContentType.MOVIE) {
                for (const platform of data.movieProvider) {
                    if (selectedPlatforms.includes(platform.id)) {
                        arraySelected.push(platform);
                    }
                }
            } else {
                for (const platform of data.showProvider) {
                    if (selectedPlatforms.includes(platform.id)) {
                        arraySelected.push(platform);
                    }
                }
            }
        }
        return arraySelected;
    }, [user, selectedType, data.movieProvider, data.showProvider, selectedPlatforms]);

    return (
        <div className="accountMobileContainer-content -platform">
            <h2 className="accountMobileContainer-content_subtitle">{t('account_page.mobile.select_providers')}</h2>
            <ChangeType selectedType={selectedType} onChange={(type) => setSelectedType(type)} />
            <MultiFilterSelector
                datas={selectedType === ContentType.MOVIE ? data.movieProvider : data.showProvider}
                type={'provider'}
                contentType={selectedType}
                selectedDatas={selectedProviders}
                onChange={(newSelected) => {
                    const platforms = newSelected as Provider[];
                    handleChangeArray(platforms);
                }}
            />
            <Button onClick={handleSave} text={t('account_page.mobile.save')} />
            <LoadingModal isVisible={loading} />
            <Toast message={error || success} onClose={handleCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default PlatformsView;
