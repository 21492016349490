import axios, { AxiosError } from 'axios';
import QueryString from 'qs';
import { PagedContents, WatchedType, SavedType, ResultType } from '../models/user';
import { baseUrlPlatform } from '../resources/constants';
import { ContentType } from '../utilities/enum/contentType';

export const getWatchedContents = (
    type: ContentType,
    userId: number,
    page = 1,
    pageSize = 10,
): Promise<PagedContents<WatchedType[]>> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}watched/my-list/${type}/${userId}`, {
                params: { page, pageSize },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.results) {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const getSavedContents = (
    type: ContentType,
    userId: number,
    page = 1,
    pageSize = 10,
): Promise<PagedContents<SavedType[]>> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}saved/my-list/${type}/${userId}`, {
                params: { page, pageSize },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.results) {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const getLatestSavedContents = (
    userId: number,
    page = 1,
    pageSize = 10,
): Promise<PagedContents<SavedType[]>> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}saved/latest/${userId}`, {
                params: { page, pageSize },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.results) {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const getLatestWatchedContents = (
    userId: number,
    page = 1,
    pageSize = 10,
): Promise<PagedContents<WatchedType[]>> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}watched/latest/${userId}`, {
                params: { page, pageSize },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.results) {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const removeFromSaved = (contentId: number): Promise<ResultType> => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${baseUrlPlatform}saved/remove/${contentId}`)
            .then((response) => {
                if (response.data.status === 'ok') {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const removeFromWatched = (contentId: number): Promise<ResultType> => {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${baseUrlPlatform}watched/remove/${contentId}`)
            .then((response) => {
                if (response.data.status === 'ok') {
                    resolve(response.data);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const addToWatched = (item: WatchedType): Promise<WatchedType> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrlPlatform}watched/add`, item)
            .then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    resolve(response.data.result[0]);
                } else {
                    reject(response.data);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const changeStatusWatched = (isLike: boolean, userId: number, id: number): Promise<WatchedType> => {
    return new Promise((resolve, reject) => {
        axios
            .put(`${baseUrlPlatform}watched/change-like/${id}`, { isLike, userId })
            .then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    resolve(response.data.result[0]);
                } else {
                    reject(response.data);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const addToSaved = (item: SavedType): Promise<SavedType> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${baseUrlPlatform}saved/add`, item)
            .then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    resolve(response.data.result[0]);
                } else {
                    reject(response.data);
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const getSingleSaved = (contentId: number, type: ContentType, userId: number): Promise<SavedType> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}saved/single/${type}/${contentId}`, {
                params: { userId },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    resolve(response.data.result[0]);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((e) => {
                if (e instanceof AxiosError) {
                    reject(e.response?.data);
                }
                if (e.response?.data.error) {
                    reject(e.response?.data);
                }
                reject(e);
            });
    });
};

export const getSingleWatched = (contentId: number, type: ContentType, userId: number): Promise<WatchedType> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}watched/single/${type}/${contentId}`, {
                params: { userId },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response) => {
                if (response.data.result && response.data.result.length > 0) {
                    resolve(response.data.result[0]);
                } else {
                    reject({ error: response.data });
                }
            })
            .catch((error) => {
                if (error instanceof AxiosError) {
                    if (error.response?.status === 404) {
                        reject({ status: 404, message: error.response?.data });
                    }
                    reject(error.response?.data);
                }
                if (error.response?.data.error) {
                    reject(error.response?.data);
                }
                reject(error);
            });
    });
};
