import { FilterParams, Genre, Movie, Provider } from '../models/data';
import { baseUrlPlatform } from '../resources/constants';
import axios from 'axios';
import QueryString from 'qs';

export const getLatestMovie = (): Promise<number> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}movies/latest`)
            .then((response: any) => resolve(response.data.id))
            .catch((error) => reject(error));
    });
};

export const getMoviesGenres = (): Promise<Genre[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}movies/genres`)
            .then((response: any) => resolve(response.data.results ?? []))
            .catch((error) => reject(error));
    });
};

export const getMoviesProviders = (): Promise<Provider[]> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}movies/providers`)
            .then((response: any) => resolve(response.data.results ?? []))
            .catch((error) => reject(error));
    });
};

export const getMovieRandom = (): Promise<Movie> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}movies/random`)
            .then((response: any) => resolve(response.data.result))
            .catch((error) => reject(error));
    });
};

export const getMovieDetail = (id: number): Promise<Movie> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}movies/detail/${id}`)
            .then((response: any) => resolve(response.data.result))
            .catch((error) => reject(error));
    });
};

export const getMovieFiltered = (filter: FilterParams): Promise<Movie> => {
    return new Promise((resolve, reject) => {
        axios
            .get(`${baseUrlPlatform}movies/filtered`, {
                params: { ...filter },
                paramsSerializer: (params) => QueryString.stringify(params, { encode: false }),
            })
            .then((response: any) => resolve(response.data.result))
            .catch((error) => reject(error));
    });
};
