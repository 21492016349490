import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import Button from '../../components/Button/Button';
import { openPrivacyPolicy } from '../../utilities/functions';

interface Props {
    isVisible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}

const ConfirmSocialRegistrationModal: FC<Props> = ({ isVisible, onConfirm, onCancel }) => {
    const { t } = useTranslation();
    return (
        <Modal open={isVisible} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <div className="modalBox -confirmModal -social">
                <h2 className="modalBox-title">{t('login_page.confirm_social_login')}</h2>
                <div className="modalBox-subtitle">
                    <span>{t('login_page.confirm_social_login_subtitle')} </span>
                    <Button
                        text={t('login_page.confirm_social_login_privacy')}
                        onClick={openPrivacyPolicy}
                        styleClass="linkPurple"
                    />
                </div>
                <div className="modalBox-buttonsRow">
                    <Button
                        onClick={onCancel}
                        text={t('login_page.confirm_social_login_cancel')}
                        styleClass="linkOpacity"
                    />
                    <Button onClick={onConfirm} text={t('login_page.confirm_social_login_confirm')} />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmSocialRegistrationModal;
