import { FC, useCallback, useMemo, useState } from 'react';
import { ReducerUser, Reducers } from '../../../../models/reducers';
import { useDispatch, useSelector } from 'react-redux';
import SettingsItem from '../../../../components/SettingsItem/SettingsItem';
import { SettingsUser, SettingsUserComplete } from '../../../../models/user';
import { editUserSettings, removeUser } from '../../../../api/users-requests';
import { logoutUser, setUser } from '../../../../store/actions/user';
import LoadingModal from '../../../../components/Modals/LoadingModal';
import Toast from '../../../../components/Toast';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';

const SettingsTab: FC = () => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const user: ReducerUser = useSelector((store: Reducers) => store.user);
    const dispatch = useDispatch();

    const handleChangeStatus = useCallback(
        (key: string) => {
            if (user.user) {
                setLoading(true);
                const currentValue = user.user.settings[key as keyof SettingsUser];
                const editSettingsObject: SettingsUser = {
                    ...user.user.settings,
                    [key as keyof SettingsUser]: !currentValue,
                };
                editUserSettings(user.user.settings.id, editSettingsObject)
                    .then((response) => {
                        if ((response as SettingsUserComplete).id && user.user) {
                            dispatch(
                                setUser({
                                    ...user.user,
                                    settings: response as SettingsUserComplete,
                                }),
                            );
                            setSuccess(t('account_page.desktop.settings.preferences_saved'));
                        } else {
                            setError(t('errors.saving_preference_error'));
                        }
                    })
                    .catch((error) => {
                        if (error.error) {
                            setError(t(`errors.${error.error}`) ?? t('errors.saving_preference_error'));
                        } else {
                            setError(error.toString());
                        }
                    })
                    .finally(() => setLoading(false));
            }
        },
        [dispatch, t, user.user],
    );

    const handleCloseToast = useCallback(() => {
        setError('');
        setSuccess('');
    }, []);

    const handleRemoveAccount = useCallback(() => {
        if (user.user) {
            setLoading(true);
            removeUser(user.user.id)
                .then((response) => {
                    if (response.status === 'ok' && user.user) {
                        setSuccess(t('account_page.desktop.settings.account_removed'));
                        dispatch(logoutUser());
                    } else {
                        setError(t('errors.error_removing'));
                    }
                })
                .catch((error) => {
                    if (error.error) {
                        setError(t(`errors.${error.error}`) ?? t('errors.saving_preference_error'));
                    } else {
                        setError(error.toString());
                    }
                })
                .finally(() => setLoading(false));
        }
    }, [dispatch, t, user.user]);

    const keyToPhrase = useCallback(
        (key: string) => {
            switch (key) {
                case 'randomSearchWithPreferences':
                    return {
                        title: t('account_page.desktop.settings.use_preferences_title'),
                        subtitle: t('account_page.desktop.settings.use_preferences_subtitle'),
                    };
                default:
                    return {
                        title: '',
                        subtitle: '',
                    };
            }
        },
        [t],
    );

    const settingsList = useMemo(() => {
        if (user.user && user.user.settings) {
            const settingsKeys = Object.keys(user.user.settings);
            if (settingsKeys.length > 0) {
                return settingsKeys.map((key) => {
                    if (key !== 'id') {
                        const objectPhrases = keyToPhrase(key);
                        return (
                            <SettingsItem
                                key={`settingKey${key}`}
                                title={objectPhrases.title}
                                subtitle={objectPhrases.subtitle}
                                value={user.user?.settings[key as keyof SettingsUser] as boolean}
                                onChange={() => {
                                    handleChangeStatus(key);
                                }}
                            />
                        );
                    } else {
                        return null;
                    }
                });
            }
        }
        return null;
    }, [handleChangeStatus, keyToPhrase, user.user]);

    return (
        <div className="accountContainer-tabRendered_settingsContainer">
            {settingsList}
            <Button
                text={t('account_page.desktop.settings.remove_account')}
                styleClass="linkDelete"
                onClick={() => {
                    setShowDeleteAccountModal(true);
                }}
            />
            <ConfirmModal
                isVisible={showDeleteAccountModal}
                answer={t('account_page.desktop.settings.remove_account_confirm')}
                cancelText={t('account_page.desktop.settings.remove_account_confirm_no')}
                confirmText={t('account_page.desktop.settings.remove_account_confirm_yes')}
                onCancel={() => {
                    setShowDeleteAccountModal(false);
                }}
                onConfirm={handleRemoveAccount}
            />
            <LoadingModal isVisible={loading} />
            <Toast message={success || error} onClose={handleCloseToast} type={success ? 'success' : 'error'} />
        </div>
    );
};

export default SettingsTab;
